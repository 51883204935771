import css from "../../css/footer/footer.module.css";

import React from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import { useAuth } from "../common/Auth";
import { isTeam } from "../../service/team_page";

const AuthModal = dynamic(() => import("../auth/AuthModal"), { ssr: false });

const ToolsAndInformation = () => {
    const { profile, isAuthenticated } = useAuth();

    const showLoginBtn = !parseInt(process.env.DISABLE_LOGIN);
    let myAccountPath = "/user";

    if (isAuthenticated && profile.permissions.includes("agent-leads.list")) {
        myAccountPath = "/user/opportunities";
    }

    const isTeamPage = profile && isTeam(profile.agent);

    return (
        <div className={css.menuList}>
            <div className={css.menuTitle}>Tools &amp; Information</div>
            <div>
                <Link
                    href="/find-agent"
                    as="/real_estate_agents"
                    className={css.menuItem}
                >
                    Find an Agent
                </Link>

                {showLoginBtn && (
                    <>
                        {isAuthenticated && (
                            <a href={myAccountPath} className={css.menuItem}>
                                {isTeamPage ? "Team" : "My"} Account
                            </a>
                        )}

                        {!isAuthenticated && (
                            <AuthModal className={css.menuItem}>
                                Sign In/Sign Up
                            </AuthModal>
                        )}
                    </>
                )}

                <Link
                    href="/loans"
                    as="/mortgages/loans/pre-approved"
                    className={css.menuItem}
                >
                    Mortgage Loans
                </Link>

                <Link
                    href="/loans"
                    as="/mortgages/loans/pre-approved"
                    className={css.menuItem}
                >
                    Pre-Approval
                </Link>

                <Link
                    href="/my-property"
                    as="/myproperty/claim_property"
                    className={css.menuItem}
                >
                    Manage {isTeamPage ? "Team" : "your"} Property
                </Link>

                <a href="/sitemap" className={css.menuItem}>
                    Site Map
                </a>
            </div>
        </div>
    );
};

export default ToolsAndInformation;
